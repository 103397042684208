@import "src/shared-ui/styles/kit.module"
@import "../../../shared-ui/styles/breakpoints"

.cards
    &__games
        margin-top: toRem(-10)
        gap: toRem(50)
        display: flex
        flex-wrap: wrap
        justify-content: center

    &__card
        display: flex
        justify-content: center
        flex-direction: row
        flex-wrap: nowrap
        gap: 1.875rem


@media (max-width: $mobilePhone)
    .cards
        &__games
            gap: toRem(20) toRem(50)
        &__card
            display: flex
            justify-content: center
            gap: 0.3125rem
            flex-direction: row
            margin-left: 0
            padding-bottom: toRem(10)
