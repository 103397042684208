@import "../../shared-ui/styles/kit.module"
@import "../../shared-ui/styles/breakpoints"

.header
  overflow: hidden
  color: $CommonText
  display: block
  height: toRem(1110)
  padding-left: toRem(320)
  position: relative

  &__background
    display: flex
    justify-content: flex-end
    overflow: hidden

  &__info
    position: relative
    background: none
    box-sizing: border-box
    z-index: 2
  
  &__video
    position: absolute
    width: toRem(1500)
    padding-top: toRem(60)
    left: 22%
    z-index: -1

  &__dots
    display: none

  &__title
    @include BebasParagraph()
    width: toRem(654)
    margin: 0
    padding-top: toRem(70)
    padding-bottom: toRem(39)

  &__link
    border: none
    background: none
    color: $CommonText
    &:hover
      cursor: pointer

  &__videoControls
    height: toRem(900)
    width: toRem(1500)
    margin-top: toRem(70)
    left: 22%
    position: absolute
    transition: all 0.2s ease  
    z-index: 1

  &__volumeControls
    margin-right: toRem(20)
    padding-top: toRem(745)
    justify-content: end
    display: flex
    opacity:0
    z-index: 2
    &:hover
      opacity: 1

  &__svg 
    width: 28px
    height: 28px
    fill: #fff
    stroke: #fff
    cursor: pointer

  &__hidden
    display: none

  &__volumeButton
    border: none
    background-color: transparent

@media (max-width: $mobilePhone)
  .header
    padding-left: 0
    height: toRem(680)

    &__info
      padding-left: toRem(30)
      top: toRem(30)
      z-index: 0

    &__dots
      display: block
      position: absolute
      box-sizing: content-box
      z-index: -1
      width: toRem(162)
      height: toRem(134)
      padding-top: toRem(329)
      left: 60% 

    &__title
      @include BebasMobile
      width: toRem(231)
      padding-top: 0
      padding-bottom: toRem(15)

    &__socials
      display: none

    &__video
      display: none

    &__image
      position: absolute
      width: toRem(965)
      height: toRem(542)
      background-image: url(../../shared-ui/images/header/background-mobile.png)
      background-position-x: toRem(-334)
      background-position-y: toRem(4)
      background-repeat: no-repeat
      background-size: contain
      left: 22%
      z-index: -1

    &__videoControls
      display: none
