@import '../../shared-ui/styles/kit.module'
@import "../../shared-ui/styles/breakpoints"

.chart
    max-width: 100vw
    padding-top: toRem(100)
    padding-left: toRem(520)
    padding-right: toRem(520)

    &__title
        text-align: center
        @include BebasH3

    &__main
        padding-top: toRem(50)
        display: flex
        flex-direction: column
        align-items: center

    &__info
        width: toRem(275)

@media (max-width: $mobilePhone)
    .chart
        overflow: hidden
        padding-top: toRem(60)
        padding-left: toRem(70)
        padding-right: toRem(70)
        &__title
            @include BebasH4


