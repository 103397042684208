@font-face 
    font-family: 'Khand'
    src: url('../fonts/Khand-Medium.woff') format('woff'), url('../fonts/khand-medium.ttf') format('truetype'), url('../fonts/Khand-Medium.svg#Khand-Medium') format('svg')
    font-weight: 500
    font-style: normal
    font-display: swap


@font-face 
    font-family: 'Jura'
    src: url('../fonts/Jura-Regular.woff') format('woff'), url('../fonts/Jura-Regular.ttf') format('truetype'), url('../fonts/Jura-Regular.svg#Jura-Regular') format('svg')
    font-weight: normal
    font-style: normal
    font-display: swap


@font-face 
    font-family: 'Jura'
    src: url('../fonts/Jura-Medium.woff') format('woff'), url('../fonts/Jura-Medium.ttf') format('truetype'), url('../fonts/Jura-Medium.svg#Jura-Medium') format('svg')
    font-weight: 500
    font-style: normal
    font-display: swap

@font-face 
    font-family: 'Jura'
    src: url('../fonts/Jura-DemiBold.woff') format('woff'), url('../fonts/Jura-DemiBold.ttf') format('truetype'), url('../fonts/Jura-DemiBold.svg#Jura-DemiBold') format('svg')
    font-weight: 600
    font-style: normal
    font-display: swap

@font-face 
    font-family: 'Bebas Neue'
    src: url('../fonts/BebasNeueBold.woff') format('woff'), url('../fonts/BebasNeueBold.ttf') format('truetype'), url('../fonts/BebasNeueBold.svg#BebasNeueBold') format('svg')
    font-weight: bold
    font-style: normal
    font-display: swap


@font-face 
    font-family: 'Bebas Neue'
    src: url('../fonts/BebasNeueRegular.woff') format('woff'), url('../fonts/BebasNeueRegular.ttf') format('truetype'), url('../fonts/BebasNeueRegular.svg#BebasNeueRegular') format('svg')
    font-weight: normal
    font-style: normal
    font-display: swap


@font-face 
    font-family: 'Khand'
    src: url('../fonts/Khand-Regular.woff') format('woff'), url('../fonts/khand-regular.ttf') format('truetype'), url('../fonts/Khand-Regular.svg#Khand-Regular') format('svg')
    font-weight: normal
    font-style: normal
    font-display: swap

@font-face 
    font-family: 'Inter'
    src: url('../fonts/Inter.woff') format('woff'), url('../fonts/Inter.ttf') format('truetype'), url('../fonts/Inter.svg#Inter') format('svg')
    font-weight: normal
    font-style: normal
    font-display: swap

