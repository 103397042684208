@import "src/shared-ui/styles/kit.module"
@import "../../shared-ui/styles/breakpoints"

.supportGames
	max-width: 100vw
	padding: toRem(100) toRem(320) 0 toRem(320)
	&__title
		text-align: center
		@include BebasH3()
		padding: toRem(50)
	&__main
		display: flex
		flex-direction: column
		align-items: center
@media (max-width: $mobilePhone)
	.supportGames
		overflow: hidden
		padding: toRem(50) toRem(20) 0 toRem(20)
		&__title
			text-align: center
			@include BebasMobile()
			padding: 0 0 toRem(50)
