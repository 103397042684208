@import "shared-ui/styles/kit.module"
@import "shared-ui/styles/breakpoints"

body
	margin: 0
	-webkit-font-smoothing: antialiased
	-moz-osx-font-smoothing: grayscale


code
	font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New', monospace

html
	font-size: .83334vw

@media (max-width: $mobilePhone)
	html
		font-size: 4.26vw
