@import "../../styles/kit.module"
@import "../../styles/breakpoints"

//width variables
$button-width: 296
$eco-width: 148
$table-width: 203
$roadmap-width: 123

.frame__p
  position: absolute
  top: 20%
  width: 100%
  text-align: center
  color: $CommonText
  @include KhandParagraph1()

.button
  position: relative
  width: toRem($button-width)

  svg
    width: toRem($button-width)
    height: toRem(89)

  &:hover
    cursor: pointer

  p
    top: 30%

.eco
  position: relative
  margin: 0 auto
  width: toRem($eco-width)

  svg
    width: toRem($eco-width)
    height: toRem(190)

  p
    top: 0

  img
    position: absolute
    right: toRem(12)
    top: toRem(5)
    width: 60%

.ecoBackground
    position: absolute
    top: toRem(10)
    left: toRem(16)
    z-index: -1

    svg
      width: toRem(129)
      height: toRem(81)

.table
  position: relative
  width: toRem($table-width)

  &__japanBox
    width: toRem(400)
    height: toRem(60)

  svg
    width: toRem(220)
    height: toRem(80)

.roadmap
    position: relative
    width: toRem($roadmap-width)

    svg
      width: toRem($roadmap-width)
      height: toRem(57)

@media (max-width: $mobilePhone)
  //mobile width variables
  $mobileButton-width: 154
  $mobileEco-width: 76
  $mobileTable-width: 144
  $mobileRoadmap-width: 103
  
  .frame__p
    @include KhandMobileButton()

  p
    top: 25% !important
    @include KhandMobileButton

  .button
    width: toRem($mobileButton-width)

    svg
      width: toRem($mobileButton-width)
      height: toRem(47)

  .eco
    width: toRem(98)

    svg
      width: toRem(98)
      height: toRem(128)

    img
      margin-right: toRem(0)
      right: toRem(10)
      width: 65%

    p
      top: 0 !important

  .ecoBackground
    top: toRem(10)
    left: toRem(10)

    svg
      width: toRem(86)
      height: toRem(54)

  .table
    width: toRem($mobileTable-width)

    svg
      width: toRem(190)
      height: toRem(41)

    p
      @include KhandSmall()
      font-size: 12px
      padding-left: toRem(52)


  .roadmap
    width: toRem($mobileRoadmap-width)

    svg
      width: toRem($mobileRoadmap-width)
      height: toRem(48)

    p
      @include Font(toRem(20))
