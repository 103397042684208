@import "../../shared-ui/styles/kit.module"
@import "src/shared-ui/styles/constants.module"
@import "../../shared-ui/styles/breakpoints"

.benefits
  margin: 0
  padding-top: toRem(64)
  width: toRem(1000)
  position: relative

  &__japan
    padding-top: toRem(10)
    @include KhandHead
    font-size: toRem(12) !important

  &__text
    padding-left: toRem(68)
    text-align: left
    width: toRem(350)
    font-size: toRem(18)


  &__wrap
    width: 100%
    display: flex
    justify-content: center

  &__table
    display: grid
    margin: 0 auto
    grid-template-columns: repeat(4, 1fr)
    grid-template-rows: repeat(3, 1fr)
    column-gap: toRem(14)
    row-gap: toRem(14)

  &__title
    position: relative
    text-align: center
    @include BebasH1()
    margin: 0 auto toRem(50)

@media (max-width: $mobilePhone)
  .benefits
    margin: 0
    padding-top: toRem(24)
    padding-left: toRem(0)
    max-width: toRem(320)

    &__japan
      padding-top: toRem(10)
      @include KhandMobileButton()

    &__text
      padding-top: toRem(2)
      padding-left: toRem(45)
      text-align: left
      width: toRem(280)

    &__table
      margin: 0 toRem(60)
      grid-template-columns: repeat(1, 1fr)
      grid-template-rows: repeat(6, 1fr)
      column-gap: toRem(10)
      row-gap: toRem(8)

    &__title
      margin: 0 auto toRem(21)
      @include BebasMobile()
      position: initial
      text-align: center

