@import "../../../shared-ui/styles/kit.module"
@import "../../../shared-ui/styles/breakpoints"

.block
    position: relative
    padding-bottom: toRem(60)
    &__information
        margin-left: toRem(120)
        width: toRem(455)
        &_turn
            left: initial
            right: toRem(125)
            text-align: right
    &__title
        margin-bottom: toRem(40)
        @include BebasH48
    &__text
        @include JuraParagraph
    &__benefits
        position: absolute
        display: flex
        flex-direction: column
        bottom: toRem(0)
        &_turn
            align-items: flex-end
    &__image
        position: absolute
        top: 0
        left: 0
        width: toRem(85)
        height: toRem(85)
        &_turn
            left: initial
            right: toRem(-28)
    &__border
        margin-top: toRem(20)
        width: toRem(606)
        height: toRem(215)
        &_turn
            transform: scale(-1, 1)

@media (max-width: $mobilePhone)
    .block
        padding-bottom: toRem(20)
        &__information
            margin-left: 0
            position: relative
            width: toRem(262)
            left: toRem(60)
            &_turn
                left: initial
                right: toRem(0)
        &__title
            margin-bottom: toRem(15)
            @include BebasH4
            line-height: toRem(28)
        &__image
            width: toRem(45)
            height: toRem(45)
            &_turn
                right: toRem(15)
        &__border
            margin-top: toRem(45)
            width: toRem(314)
            height: initial
