@import "../../shared-ui/styles/kit.module"
@import "src/shared-ui/styles/constants.module"
@import "../../shared-ui/styles/breakpoints"

.navbar
  text-transform: none
  padding: toRem(31) toRem(290) toRem(50) 0
  @include TransitionSlow()
  display: flex
  justify-content: space-between
  white-space: nowrap
  
  &__left
    display: flex
    gap: toRem(24)

  &__line 
    width: 100%
    margin: toRem(13) toRem(20) toRem(13) toRem(20)
    background-color: #982727
    height: 1px

  &__langs
    @include KhandHead()
    display: inline-flex
    padding-right: 0 !important

    &_active
      padding-right: 0 !important
      color: $AdditionalTextAccent !important

    &_mobile
      display: none

  &__separator
    margin-right: toRem(5)
    margin-left: toRem(5)

  &__arrow
    width: toRem(35)
    height: toRem(15)
    margin-right: toRem(9)
    stroke: $MainAccent

  &__logo
    color: $CommonText
    @include Inter()
    margin-top: toRem(5)
    svg
      width: toRem(98)
      height: toRem(18)

  &__toggle
    height: toRem(32)
    width: toRem(32)
    display: none

  &__items
    @include KhandHead()
    margin: 0
    padding: 0

  &__item
    &_back
      color: $MainAccent !important
      &:hover
        color: $AdditionalTextAccent !important
        svg
          stroke: $ButtonActive

  &__socials
    ul
      margin: 0 !important
      padding-top: toRem(5)
      width: toRem(83)

  &__socialsMobile
    display: none


  //mobile styles
@media (max-width: $mobilePhone)
  .navbar
    padding: toRem(24) toRem(24) toRem(0) toRem(30)

    &__left
      gap: toRem(10)

    &__line
      display: none

    &__langs
      display: none

      &_active
        padding: 0 !important

      &_mobile
        display: flex
        justify-content: flex-end
        padding-top: toRem(30)
        padding-right: toRem(24) !important

        &_item
          padding: 0 !important

    &__separator
      margin-right: toRem(5)
      margin-left: toRem(5)

    &__arrow
      display: none

    &_open
      background-color: $NavMenuBackground

    &__items
      visibility: hidden
      opacity: 0
      z-index: 1
      position: absolute
      right: 0
      top: toRem(50)
      padding-top: toRem(32)
      text-align: right
      display: flex
      flex-direction: column
      width: 100%
      height: 100%
      background-color: $NavMenuBackground

      &_open
        @include TransitionFast()
        visibility: initial
        opacity: 1

    &__toggle
      position: relative
      display: block
      cursor: pointer
      width: toRem(30)
      @include TransitionFast()

      .bar
        &__burger
          fill: $BurgerColor

          &_open
            float: right
            width: toRem(20)
            fill: $MainAccent

    &__item
      padding-right: toRem(24)!important

    &__socials
      display: none

    &__socialsMobile
      display: flex
      justify-content: flex-end
      padding-right: 1.5rem

      ul
        margin: 0 !important
        padding-top: toRem(24)
