@import "../../../shared-ui/styles/kit.module"
@import "../../../shared-ui/styles/breakpoints"

.information
    margin-top: toRem(100)
    &__inner
        display: flex
        align-items: center
        width: toRem(675)
        padding: toRem(30) toRem(20)
    &__text
        @include JuraBig
    &__image
        width: toRem(97)
        margin-right: toRem(40)

@media (max-width: $mobilePhone)
    .information
        margin-top: toRem(60)
        &__inner
            padding: 0
            width: toRem(313)
        &__text
            width: toRem(200)
            @include JuraBisexual
        &__image
            width: toRem(77)
            margin-right: toRem(10)
            margin-left: toRem(10)