@import "../../shared-ui/styles/kit.module"
@import "../../shared-ui/styles/breakpoints"

.road
	padding-top: toRem(100)
	&__main
		display: flex
		flex-direction: column
		align-items: center
	&__button
		margin-top: toRem(20)

	&__box
		display: flex
		gap: toRem(60)
		padding-left: toRem(90)
	&__image
		margin-left: toRem(450)
		position: absolute
		height: toRem(306)
		width: toRem(107)
		margin-top: toRem(300)

	&__years
		display: flex
		gap: toRem(580)
		@include BebasH3()

	&__yearsm
		display: none

	&__name
		@include BebasH3()
		color: $CommonText
		text-align: center
	&__Q
		padding-top: toRem(24)

	&__Q0
		padding-top: toRem(45)

	&__info
		display: flex
		flex-direction: column
		flex-wrap: wrap
		width: toRem(210)

	&__info2023
		width: toRem(230)

	&__infoQ4
		display: flex
		flex-direction: column
		flex-wrap: wrap
		width: toRem(250)

	&__timeline
		width: toRem(1600)

	&__timelineMobile
		display: none

	&__list
		padding-left: toRem(20)
		padding-top: toRem(5)

	&__title
		@include JuraAccent()

	&__content
		@include JuraAccent()
		display: flex
		align-items: center
		padding-left: toRem(25)

	&__circle
		margin: 0 toRem(9)
		width: toRem(3)
		height: toRem(15)
		border-radius: toRem(2)

	&__item
		margin: 0
		position: relative

	&__dot2
		display: none

	&__gradient
		position: absolute
		bottom: toRem(-200)
		width: toRem(785)
		left: toRem(761)
		z-index: -1

	&__dot
		display: none

@media (max-width: $mobilePhone)
	.road
		position: relative
		padding-top: toRem(70)
		max-width: 100vw

		&__dots
			display: flex
			align-items: center
			width: 100%

		&__dot
			position: absolute
			left: toRem(18)
			display: initial
			height: toRem(42)

		&__dot2
			position: absolute
			left: toRem(33)
			display: initial
			height: toRem(15)

		&__name
			@include BebasMobile()
			margin-left: toRem(57)
			text-align: left
			align-self: normal

		&__years
			display: none

		&__yearsm
			display: flex
			@include BebasMobile()

		&__timeline
			display: none

		&__timelineMobile
			position: absolute
			left: toRem(40)
			top: toRem(-177)
			display: flex
			height: toRem(1320)

		&__info2023
			width: toRem(250)
			padding-top: toRem(30)

		&__image
			display: none

		&__title
			@include JuraMobile()
			padding-bottom: toRem(12)

		&__list
			padding-top: toRem(10)

		&__content
			@include JuraMobile()
			padding-left: toRem(13)

		&__box
			display: flex
			gap: toRem(0)
			padding-left: toRem(0)
			flex-direction: column

		&__gradient
			display: none

		&__Q
			padding-top: toRem(30)

		&__Q0
			padding-top: toRem(30)
			margin-left: toRem(20)

		&__info
			display: flex
			flex-direction: column
			flex-wrap: wrap
			width: toRem(250)
			margin-top: toRem(30)

		&__infoQ4
			display: flex
			margin-top: toRem(0)
			flex-direction: column
			flex-wrap: wrap
			width: toRem(250)
