@import '../../shared-ui/styles/kit.module'
@import "../../shared-ui/styles/breakpoints"

.dao
  position: relative
  overflow: hidden
  display: flex
  margin: toRem(170) 0 toRem(120)
  padding: toRem(60) toRem(600) toRem(72) toRem(500)
  justify-content: center

  &__content
    width: toRem(900)
    display: flex
    z-index: 2

  &__image
    position: absolute
    width: toRem(190)
    height: toRem(173)
    margin-top: toRem(20)
    margin-left: toRem(30)

  &__text
    @include JuraBig
    position: relative
    text-align: left
    width: toRem(638)
    margin-left: toRem(300)

  &__background
    position: absolute
    top: 0
    left: 0
    width: 100%
    height: toRem(255)
    background-image: url(../../shared-ui/images/dao/dao-background.svg)
    background-repeat: no-repeat
    background-size: contain

  &__lineUpper
    width: 100%
    height: toRem(10)
    left: 0
    top: toRem(-3)
    position: absolute
    background-image: url(../../shared-ui/images/dao/dao-line.svg)
    background-repeat: no-repeat
    background-size: cover
    z-index: 2

  &__lineBottom
    width: 100%
    height: toRem(10)
    left: 0
    bottom: toRem(-3)
    position: absolute
    background-image: url(../../shared-ui/images/dao/dao-line.svg)
    background-repeat: no-repeat
    background-size: cover
    z-index: 2

@media (max-width: $mobilePhone)
  .dao
    margin: toRem(280) 0 0
    padding: 0
    width: 100%

    &__image
      position: relative
      width: toRem(213)
      height: toRem(183)
      margin-top: toRem(120)
      margin-left: toRem(40)

    &__text
      @include JuraMedium
      width: toRem(207)
      height: toRem(188)
      margin-left: toRem(0)
      margin-top: toRem(-75)

    &__background
      height: toRem(326)
      width: toRem(574)
      left: toRem(0)
      background-image: url(../../shared-ui/images/dao/dao-background-mobile.svg)
      background-position: center
      background-position-x: toRem(-440)
      background-size: cover

    &__content
      width: toRem(206)
      flex-direction: column-reverse

    &__lineUpper
      top: toRem(-3)

    &__lineBottom
      bottom: toRem(88)

