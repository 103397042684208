@import "../../../shared-ui/styles/kit.module"
@import "../../../shared-ui/styles/breakpoints"

.doughnut
    position: relative
    margin-top: toRem(28)
    width: toRem(230)
    &__grafic
        position: relative
    &__round
        width: toRem(130)
        top: toRem(58)
        left: toRem(51)
        position: absolute
    &__chart
        z-index: 1
        animation: shine
        animation-duration: 3s
        animation-iteration-count: infinite
        animation-timing-function: ease
    &__present
        position: relative
        white-space: pre
        @include JuraBig
    &__footnote
        position: absolute
        &:nth-child(1)
            top: toRem(-25)
            left: toRem(150)
            width: toRem(130)
            height: toRem(24)
            transform: scale(-1, 1)
            .doughnut__present
                transform: scale(-1, 1)
                right: toRem(90)
        &:nth-child(2)
            top: toRem(5)
            left: toRem(180)
            width: toRem(130)
            height: toRem(24)
            transform: scale(-1, 1)
            .doughnut__present
                transform: scale(-1, 1)
                right: toRem(104)
        &:nth-child(3)
            top: toRem(57)
            left: toRem(205)
            width: toRem(130)
            height: toRem(24)
            transform: scale(-1, 1)
            .doughnut__present
                transform: scale(-1, 1)
                margin-right: toRem(90)
        &:nth-child(4)
            top: toRem(210)
            left: toRem(170)
            width: toRem(123)
            height: toRem(47)
            transform: scale(-1, -1)
            .doughnut__present
                transform: scale(-1, -1) translateY(toRem(-25))
                right: toRem(83)
        &:nth-child(5)
            width: toRem(140)
            height: toRem(25)
            left: toRem(-30)
            top: toRem(250)
            transform: scale(1, -1)
            .doughnut__present
                transform: scale(1, -1) translateY(toRem(-25))
                right: toRem(90)
        &:nth-child(6)
            width: toRem(140)
            height: toRem(25)
            left: toRem(-70)
            top: toRem(200)
            transform: scale(1, -1)
            .doughnut__present
                transform: scale(1, -1) translateY(toRem(-25))
                right: toRem(110)
        &:nth-child(7)
            width: toRem(140)
            height: toRem(25)
            left: toRem(-105)
            top: toRem(55)
            .doughnut__present
                right: toRem(165)
        &:nth-child(8)
            width: toRem(170)
            height: toRem(25)
            left: toRem(-100)
            top: toRem(0)
            .doughnut__present
                right: toRem(80)
        &:nth-child(9)
            width: toRem(140)
            height: toRem(25)
            left: toRem(-55)
            top: toRem(-25)
            .doughnut__present
                right: toRem(80)

@media (max-width: $mobilePhone)
    .doughnut
        margin: 0 auto
        width: toRem(135)
        &__round
            position: absolute
            top: toRem(35)
            left: toRem(33)
            width: toRem(70)
        &__present
            @include JuraBisexual
        &__footnote
            &:nth-child(1)
                top: toRem(-25)
                left: toRem(85)
                width: toRem(130)
                height: toRem(24)
                transform: scale(-1, 1)
                .doughnut__present
                    transform: scale(-1, 1)
                    right: toRem(20)
            &:nth-child(2)
                top: toRem(4)
                left: toRem(100)
                width: toRem(130)
                height: toRem(24)
                transform: scale(-1, 1)
                .doughnut__present
                    transform: scale(-1, 1)
                    right: toRem(25)
            &:nth-child(3)
                top: toRem(30)
                left: toRem(110)
                width: toRem(120)
                height: toRem(24)
                transform: scale(-1, 1)
                .doughnut__present
                    transform: scale(-1, 1)
                    left: toRem(50)
            &:nth-child(4)
                top: toRem(110)
                left: toRem(100)
                width: toRem(123)
                height: toRem(47)
                transform: scale(-1, -1)
                .doughnut__present
                    transform: scale(-1, -1) translateY(toRem(-18))
                    right: toRem(25)
            &:nth-child(5)
                width: toRem(140)
                height: toRem(25)
                left: toRem(-70)
                top: toRem(140)
                transform: scale(1, -1)
                .doughnut__present
                    transform: scale(1, -1) translateY(toRem(-18))
                    right: toRem(10)
            &:nth-child(6)
                overflow: hidden
                width: toRem(145)
                height: toRem(35)
                left: toRem(-100)
                top: toRem(105)
                transform: scale(1, -1)
                .doughnut__present
                    transform: scale(1, -1) translateY(toRem(-18))
                    right: toRem(0)
            &:nth-child(7)
                //overflow: hidden
                width: toRem(110)
                height: toRem(25)
                left: toRem(-90)
                top: toRem(30)
                .doughnut__present
                    text-align: right
                    width: toRem(58)
                    white-space: normal
                    word-break: break-all
                    right: toRem(-15)
            &:nth-child(8)
                overflow: hidden
                width: toRem(170)
                height: toRem(25)
                left: toRem(-90)
                top: toRem(-3)
                .doughnut__present
                    right: toRem(-20)
            &:nth-child(9)
                width: toRem(140)
                height: toRem(25)
                left: toRem(-80)
                top: toRem(-25)
                .doughnut__present
                    right: toRem(-20)

@keyframes shine
    0%
        filter: drop-shadow(0 0 toRem(13) $TokenColor6)
    50%
        filter: drop-shadow(0 0 toRem(15) $TokenColor4)
    100%
        filter: drop-shadow(0 0 toRem(13) $TokenColor6)