@import "../../shared-ui/styles/kit.module"
@import "../../shared-ui/styles/breakpoints"

.background
    top: 0
    position: absolute
    width: 100%
    height: 100%
    pointer-events: none
    &__image
        position: absolute
        &_1
            width: toRem(120)
            left: toRem(137)
            top: toRem(1060)
        &_2
            width: toRem(267)
            left: toRem(300)
            top: toRem(550)
        &_3
            width: toRem(185)
            right: toRem(180)
            top: toRem(1060)
        &_4
            top: toRem(2580)
            left: 0
            width: 100%
        &_5
            width: toRem(120)
            top: toRem(3560)
            right: toRem(177)
        &_6
            width: toRem(267)
            left: toRem(220)
            top: toRem(4050)
        &_7
            width: toRem(380)
            right: 0
            top: toRem(4350)
        &_8
            top: toRem(5550)
            right: 0
            width: toRem(262)
        &_9
            transform: scale(-1, 1)
            width: toRem(267)
            top: toRem(5420)
            left: toRem(-170)
        &_10
            top: toRem(5650)
            width: toRem(120)
            left: toRem(250)

@media (max-width: $mobilePhone)
    .background
        &__image
            display: none
            &_7
                display: block
                right: toRem(25)
                width: toRem(125)
                top: toRem(4650)
