@import "../../../../shared-ui/styles/kit.module"
@import "../../../../shared-ui/styles/breakpoints"

.background
    position: relative
    &__svg
        position: absolute
        width: toRem(675)
        height: toRem(166)

@media (max-width: $mobilePhone)
    .background
        &__svg
            top: toRem(-12)
            left: 50%
            transform: translateX(-50%)
            position: absolute
            width: toRem(313)
            height: toRem(122)