@import "src/shared-ui/styles/kit.module"
@import "src/shared-ui/styles/breakpoints"

.cookie
	padding: toRem(35) toRem(180)
	&__back
		color: white
		padding-bottom: toRem(20)
	&__title
		@include BebasH2
		font-size: toRem(45)
		text-align: center
		padding: toRem(40) 0 0 
	&__subtitle
		width: toRem(400)
		margin: 0 auto
		@include JuraParagraph
		font-size: toRem(19)
		text-align: center
		font-weight: normal
		padding: 0 0 toRem(40)
	&__content
		width: toRem(716)
		margin: 0 auto

@media (max-width: $mobilePhone)
	.cookie
		padding: toRem(35) toRem(10)
		&__subtitle
			width: 100%
		&__content
			width: 100%