@import "../../shared-ui/styles/kit.module"

.wp
  display: flex
  justify-content: center
  flex-direction: column
  align-items: center
  gap: toRem(100)
  width: 100%
  max-width: 100vw
  padding-top: toRem(50)

  &__name
    @include BebasH3()
    color: $CommonText
    text-align: center
