@import "../../shared-ui/styles/kit.module"
@import "../../shared-ui/styles/breakpoints"

.intersection
    padding-top: toRem(150)
    padding-left: toRem(430)
    &__content
        display: flex
    &__title
        @include BebasH3
    &__information
        margin-top: toRem(70)
        @include JuraParagraph
        width: toRem(460)
        display: flex
        flex-direction: column
        & > *
            margin-top: toRem(25)
    &__map
        width: toRem(608)
    &__line
        height: toRem(2) 
        width: toRem(265)
        background: linear-gradient(to right, $InterColor1, $InterColor2, $InterColor3)
    &__background
        z-index: -1
        position: absolute
        right: toRem(235)
        height: toRem(520)
    &__beauty
        position: absolute
        left: toRem(533)
        width: toRem(588)
        bottom: toRem(-20)

@media (max-width: $mobilePhone)
    .intersection
        padding-top: toRem(80)
        padding-left: toRem(24)
        height: auto
        &__content
            flex-direction: column
        &__title
            @include BebasH4
        &__information
            margin-top: 0
            width: toRem(270)
            @include JuraBisexual
        &__line
            width: toRem(270)
            height: toRem(4)
        &__map
            position: relative
            left: toRem(-16)
            width: 100%
        &__background
            display: none
        &__beauty
            display: none
