@import "src/shared-ui/styles/kit.module"
@import "../../../../shared-ui/styles/breakpoints"

.card
	max-width: toRem(175)
	max-height: toRem(40)
	object-fit: contain

@media (max-width: $mobilePhone)
	.card
		max-height: toRem(35)
		&__person
			height: toRem(20)
			margin: toRem(5) toRem(5) toRem(15) toRem(20)
