@import "../../shared-ui/styles/kit.module"
@import "../../shared-ui/styles/breakpoints"

.links
  display: flex
  justify-content: space-between
  width: toRem(130)
  margin-top: toRem(41)
  margin-left: toRem(26)

  &__discord
    width: toRem(22)
    height: toRem(17)
    list-style-type: none

  &__telegram
    width: toRem(20)
    height: toRem(17)
    list-style-type: none

  &__twitter
    width: toRem(17)
    height: toRem(17)
    list-style-type: none

@media (max-width: $mobilePhone)
  .links
    &__discord
      width: toRem(34)
      height: toRem(26)

    &__telegram
      width: toRem(30)
      height: toRem(26)

    &__twitter
      width: toRem(26)
      height: toRem(26)
