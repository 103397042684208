@import '../../shared-ui/styles/kit.module'
@import "../../shared-ui/styles/breakpoints"

.cbt
    margin-top: toRem(175)
    padding-left: toRem(306)
    display: flex
    &__block
        margin-right: toRem(100)
    &__paragraph
        margin-top: toRem(20)

@media (max-width: $mobilePhone)
    .cbt
        margin-top: toRem(50)
        padding-left: toRem(30)
        display: flex
        flex-direction: column
        &__paragraph
            margin-top: toRem(15)
            @include JuraBisexual
            font-weight: 400
        &__block
            margin-bottom: toRem(50)
            margin-right: initial


