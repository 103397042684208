@import "../../shared-ui/styles/kit.module"
@import "../../shared-ui/styles/breakpoints"

.subFooter
    overflow: hidden
    margin-top: toRem(100)
    position: relative
    height: toRem(1080)
    &__title
        position: relative
        z-index: 3
        @include BebasH48
        padding-top: toRem(75)
        margin-left: toRem(474)
        text-align: left
        width: toRem(637)
    &__accent
        padding: toRem(20) 0
        color: $AdditionalTextAccent
        width: toRem(380)
    &__button
        margin-left: toRem(474)
        z-index: 2
    &__link
      border: none
      background: none
      color: $CommonText
      &:hover
        cursor: pointer
    &__benefit
        display: none
    &__background
        display: none
    &__video
       position: absolute
       width: toRem(1500)
       left: 22%

@media (max-width: $mobilePhone)
    .subFooter
        margin-top: toRem(100)
        padding-top: toRem(100)
        position: relative
        height: toRem(550)
        &__title
            margin-left: toRem(30)
            width: toRem(300)
            padding: 0
            @include BebasH5
        &__accent
            padding: toRem(20) 0
            color: $AdditionalTextAccent
            width: toRem(160)
        &__button
            margin-left: toRem(30)
        &__background
            display: block
            top: 0
            height: 100%
            z-index: -1
            position: absolute
            object-fit: cover
            left: -80%
        &__benefit
            display: block
            position: absolute
            bottom: toRem(50)
            left: toRem(10)
            width: toRem(190)
        &__video
          display: none
