@import "../../../shared-ui/styles/kit.module"
@import "../../../shared-ui/styles/breakpoints"

.items
	margin-top: toRem(50)
	display: flex
	flex-wrap: wrap
	justify-content: center
	gap: toRem(30)

	&__partner
		max-width: toRem(300)
		@include JuraBig
		font-size: toRem(16)
		
	&__img
		margin-bottom: toRem(10)
		height: toRem(40)


@media (max-width: $mobilePhone)
	.items
		margin-top: toRem(40)
		gap: toRem(10)
		justify-content: space-between

		&__partner
			max-width: 40%
			@include JuraSmall
		&__img
			width: 50%
			margin-bottom: 0
