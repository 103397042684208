@import "src/shared-ui/styles/kit.module"
@import "../../shared-ui/styles/breakpoints"

.footer
	padding: toRem(15) toRem(320)
	display: flex
	justify-content: space-between
	align-items: center
	gap: toRem(10)
	&__rights
		display: flex
		gap: toRem(10)
	&__link
		color: white

@media (max-width: $mobilePhone)
	.footer
		padding: toRem(15) toRem(20)
		display: flex
		justify-content: space-between
		gap: toRem(5)
		&__logo
			width: toRem(78)
		&__rights
			display: flex
			gap: toRem(5)
			font-size: toRem(10)
			align-items: center
		&__link
			color: white
			height: fit-content