@import "../../shared-ui/styles/kit.module"
@import "../../shared-ui/styles/breakpoints"

//width variables
$image-width: 1500
$title-width: 889
$dots-width: 404
$img-width: 162

.ecosystem
  position: absolute
  top: toRem(640)
  left: toRem(455)
  margin: 0 auto
  &__parallax
    height: toRem(63)
    width: toRem(171)
    margin-bottom: toRem(64)
    margin-left: toRem(64)

  &__title
    @include BebasLarge()
    width: toRem($title-width)
    margin: 0 auto toRem(40)

  &__directions
    display: flex
    justify-content: center

  &__arrows
    position: absolute
    width: toRem(800)
    height: toRem(370)
    background-image: url(../../shared-ui/images/ecosystem/arrows.svg)
    background-position-y: center
    background-repeat: no-repeat
    background-size: contain  

  &__button
    border: none
    background: none
    color: $CommonText
    &:hover
      cursor: pointer

  &__frames
      margin: 0 auto
      width: toRem(1010)

  &__frame
    display: flex
    flex-direction: column

  &__items
    display: flex
    padding-bottom: toRem(65)
    flex-direction: row
    justify-content: space-between

    &:last-child
      padding-bottom: toRem(0)
        
  &__logoItem
    display: flex
    justify-content: center
    align-items: center

  &__item
    text-align: end

  &__logo
    margin: 0
    padding: 0
    width: toRem(165)

  &__textItem
    position: absolute
    @include KhandParagraph2()
    margin-top: toRem(120)
    right: toRem(15)
    width: toRem(114)
    color: $ButtonUnderText
    white-space: pre-wrap
    text-align: right

@media (max-width: $mobilePhone)
  .ecosystem
    position: absolute
    top: toRem(380)
    left: toRem(30)
    margin: 0 auto
    &__parallax
      height: toRem(28)
      width: toRem(75)
      margin-bottom: toRem(15)
      margin-left: toRem(20)


    &__title
      @include BebasMobile()
      text-align: left
      margin: 0 0 toRem(20)
      width: toRem(276)
    
    &__frames
      margin: 0 auto
      width: toRem(318)

    &__items
      padding-bottom: toRem(60)
      justify-content: center
      flex-wrap: wrap

    &__item
      margin: toRem(15) toRem(6) 0
      &:nth-child(1)
          margin: toRem(15) toRem(6) 0 0
      &:nth-child(3)
          margin: toRem(15) 0 0 toRem(6)

    &__arrows
      width: toRem(300)
      top: toRem(210)
      background-image: url(../../shared-ui/images/ecosystem/arrows-mobile.svg)
      &_japan
        top: toRem(240) 

    &__logo
      width: toRem(205)
      height: toRem(40)

    &__textItem
      @include KhandMobileUnderButton()
      padding-top: toRem(0)
      margin-top: toRem(80)
      right: toRem(12)
      width: toRem(70)
    