@import "../../shared-ui/styles/kit.module"
@import "../../shared-ui/styles/breakpoints"

.partners
	max-width: 100vw
	padding: toRem(100) toRem(340) 0 toRem(340)
	&__title
		text-align: center
		color: $CommonText
		@include BebasH3()
	&__image
		position: absolute
		padding-left: toRem(1175)
		width: toRem(379)
		height: toRem(314)
		box-sizing: content-box

@media (max-width: $mobilePhone)
	.partners
		margin-top: toRem(70)
		padding: 0 toRem(24) 0 toRem(24)
		&__image
			display: none
		&__title
			text-align: center
			color: $CommonText
			@include BebasMobile()
