@import "kit.module"
@import "../../shared-ui/styles/breakpoints"

.button
	@include TransitionFast()
	display: flex
	align-items: center
	justify-content: center
	height: toRem(65)
	font-size: toRem(24)
	@media (max-width: $mobilePhone)
		font-size: toRem(16)
	border-radius: toRem(8)
	background: $MainAccent
	color: $Background
	cursor: pointer
	border: none
	font-weight: 600
	&_small
		width: toRem(316)
		@media (max-width: $mobilePhone)
			width: toRem(215)
			height: toRem(44)
	&_big
		width: toRem(348)
		@media (max-width: $mobilePhone)
			width: toRem(215)
			height: toRem(44)

.wrapper
	padding: 0 toRem(320)

.link
	@include TransitionFast()
	font-weight: 400 !important
	padding-right: toRem(23)
	color: $CommonText
	cursor: pointer
	&:hover
		color: $ButtonActive
	&:last-child
		padding-right: 0
	&_active
		color: $MainAccent

//padding: 0 calc(50% - 320px)

.listItem
	&::before
		content: ""
		width: toRem(4)
		height: toRem(4)
		border-radius: 40%
		background-color: $CommonText
		position: absolute
		transform: translate(toRem(-15), toRem(8))

@media (max-width: $mobilePhone)
	.listItem
		&::before
			width: toRem(2)
			height: toRem(2)
			transform: translate(toRem(-10), toRem(6))
