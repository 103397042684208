@import "fonts"

//main colors
// $MainAccent: #AD0101
// $AdditionalAccent1: #4D0202
// $AdditionalAccent2: #490000
$MainAccent: #ECF2FE
$AdditionalAccent1: #768FC3
$AdditionalAccent2: #323F57

$NftlAccent1: #1F2D46
$NftlAccent2: #24314D

$EcolAccent1: #263858
$EcolAccent2: #113068

// tokenomics
$TokenColor1: #7E98D4
$TokenColor2: #252C42
$TokenColor3: #303A52
$TokenColor4: #4B5A7C
$TokenColor5: #1B2236
$TokenColor6: #586D9C
$TokenColor7: #6A80B2
$TokenColor8: #38435D
$TokenColor9: #424F6D

// intersection
$InterColor1: #AD0101
$InterColor2: #4D0202
$InterColor3: #490000

$Background: #000
$NavMenuBackground: #010307
$AdditionalBackground: #20201F
$AdditionalText: #C9CAC8
$CommonText: #FFF
$AdditionalDarkText: #7E827B
$AdditionalTextAccent: #6D83B8
$RedText: #FF1C1C
$BurgerColor: #5775B1

//button colors
$ButtonActive: $MainAccent
$ButtonUnderText: #EAF1FF

//gradients
$ButtonGradient: linear-gradient(to bottom right, $MainAccent, $AdditionalAccent1, $AdditionalAccent2)
$ButtonHoverGradient: linear-gradient(to bottom right, $AdditionalAccent2, $AdditionalAccent1, $MainAccent)
$ButtonTapGradient: linear-gradient(to bottom right, $AdditionalAccent2, $AdditionalAccent1, $MainAccent)

//transitions
@mixin TransitionFast()
	transition: .3s all ease

@mixin TransitionMedium()
	transition: .5s all ease

@mixin TransitionSlow()
	transition: 1s all ease


//font mixins
@mixin Font($font-size, $font-weight: normal)
	font:
		// family: 'Open Sans', sans-serif
		style: normal
		weight: $font-weight
		size: $font-size

@mixin BebasH1($font-size: toRem(60), $font-weight: bold)
	@include Font($font-size, $font-weight)
	font-family: 'Bebas Neue', sans-serif
	font-weight: $font-weight
	line-height: toRem(72)
	text-transform: uppercase

@mixin BebasH4($font-size: toRem(24), $font-weight: bold)
	@include Font($font-size, $font-weight)
	font-family: 'Bebas Neue', sans-serif
	font-weight: $font-weight
	line-height: toRem(43)
	text-transform: uppercase

@mixin BebasParagraph($font-size: toRem(68), $font-weight: 400)
	@include Font($font-size, $font-weight)
	font-family: 'Bebas Neue', sans-serif
	font-weight: $font-weight
	line-height: toRem(66)
	text-transform: uppercase

@mixin BebasLarge($font-size: toRem(48), $font-weight: bold)
	@include Font($font-size, $font-weight)
	font-family: 'Bebas Neue', sans-serif
	font-weight: $font-weight
	line-height: toRem(58)
	text-transform: uppercase

@mixin BebasMobile($font-size: toRem(24), $font-weight: 400)
	@include Font($font-size, $font-weight)
	font-family: 'Bebas Neue', sans-serif
	font-weight: $font-weight
	line-height: toRem(29)
	text-transform: uppercase

@mixin BebasH48($font-size: toRem(48), $font-weight: bold)
	@include Font($font-size, $font-weight)
	font-family: 'Bebas Neue', sans-serif
	font-weight: $font-weight
	line-height: toRem(50)
	text-transform: uppercase

@mixin BebasH2($font-size: toRem(42), $font-weight: bold)
	@include Font($font-size, $font-weight)
	font-family: 'Bebas Neue', sans-serif
	font-weight: $font-weight
	line-height: toRem(50)
	text-transform: uppercase

@mixin BebasH3($font-size: toRem(36), $font-weight: bold)
	@include Font($font-size, $font-weight)
	font-family: 'Bebas Neue', sans-serif
	font-weight: $font-weight
	line-height: toRem(43)
	text-transform: uppercase

@mixin BebasH4($font-size: toRem(24), $font-weight: bold)
	@include Font($font-size, $font-weight)
	font-family: 'Bebas Neue', sans-serif
	font-weight: $font-weight
	line-height: toRem(43)
	text-transform: uppercase

@mixin BebasH5($font-size: toRem(20), $font-weight: bold)
	@include Font($font-size, $font-weight)
	font-family: 'Bebas Neue', sans-serif
	font-weight: $font-weight
	line-height: toRem(24)
	text-transform: uppercase

@mixin KhandHead($font-size: toRem(18), $font-weight: 400)
	@include Font($font-size, $font-weight)
	font-family: 'Khand', sans-serif
	font-weight: $font-weight
	line-height: toRem(28)

@mixin KhandParagraph1($font-size: toRem(24), $font-weight: 400)
	@include Font($font-size, $font-weight)
	font-family: 'Khand', sans-serif
	font-weight: $font-weight
	line-height: toRem(37)

@mixin KhandParagraph2($font-size: toRem(20), $font-weight: 400)
	@include Font($font-size, $font-weight)
	font-family: 'Khand', sans-serif
	font-weight: $font-weight
	line-height: toRem(23)

@mixin KhandSmall($font-size: toRem(14), $font-weight: 400)
	@include Font($font-size, $font-weight)
	font-family: 'Khand', sans-serif
	font-weight: $font-weight
	line-height: toRem(21)

@mixin KhandMobileButton($font-size: toRem(16), $font-weight: 400)
	@include Font($font-size, $font-weight)
	font-family: 'Khand', sans-serif
	font-weight: $font-weight
	line-height: toRem(24)

@mixin KhandMobileUnderButton($font-size: toRem(15), $font-weight: 400)
	@include Font($font-size, $font-weight)
	font-family: 'Khand', sans-serif
	font-weight: $font-weight
	line-height: toRem(17)

@mixin JuraParagraph($font-size: toRem(18), $font-weight: 400)
	@include Font($font-size, $font-weight)
	font-family: 'Jura', sans-serif
	font-weight: $font-weight
	line-height: toRem(21)

@mixin JuraBig($font-size: toRem(20), $font-weight: 400)
	@include Font($font-size, $font-weight)
	font-family: 'Jura', sans-serif
	font-weight: $font-weight
	line-height: toRem(23)

@mixin JuraAccent($font-size: toRem(14), $font-weight: 600)
	@include Font($font-size, $font-weight)
	font-family: 'Jura', sans-serif
	font-weight: $font-weight
	line-height: toRem(16)

@mixin JuraBisexual($font-size: toRem(13), $font-weight: 600)
	@include Font($font-size, $font-weight)
	font-family: 'Jura', sans-serif
	font-weight: $font-weight
	line-height: toRem(16)

@mixin JuraMobile($font-size: toRem(13), $font-weight: 400)
	@include Font($font-size, $font-weight)
	font-family: 'Jura', sans-serif
	font-weight: $font-weight
	line-height: toRem(16)

@mixin JuraSmall($font-size: toRem(12), $font-weight: 400)
	@include Font($font-size, $font-weight)
	font-family: 'Jura', sans-serif
	font-weight: $font-weight
	line-height: toRem(14)

@mixin JuraMobule($font-size: toRem(13), $font-weight: 400)
	@include Font($font-size, $font-weight)
	font-family: 'Jura', sans-serif
	font-weight: $font-weight
	line-height: toRem(15)

@mixin JuraMedium($font-size: toRem(14), $font-weight: 400)
	@include Font($font-size, $font-weight)
	font-family: 'Jura', sans-serif
	font-weight: $font-weight
	line-height: toRem(19)

@mixin JuraBisexual($font-size: toRem(13), $font-weight: 600)
	@include Font($font-size, $font-weight)
	font-family: 'Jura', sans-serif
	font-weight: $font-weight
	line-height: toRem(16)

@mixin Inter($font-size: toRem(18), $font-weight: 400)
	@include Font($font-size, $font-weight)
	font-family: 'Inter', sans-serif
	font-weight: $font-weight
	line-height: toRem(22)

@function toRem($value)
	$remValue: calc($value / 16) + rem
	@return $remValue

// mixins for default flex
@mixin Flex($justify-content: center, $align-items: center, $flex-direction: row)
	display: flex
	justify-content: $justify-content
	align-items: $align-items
	flex-direction: $flex-direction

\:export
	buttonMain: $MainAccent
	buttonAdd: $AdditionalAccent1
	buttonAdd2: $AdditionalAccent2
	TokenColor1: $TokenColor1
	TokenColor2: $TokenColor2
	TokenColor3: $TokenColor3
	TokenColor4: $TokenColor4
	TokenColor5: $TokenColor5
	TokenColor6: $TokenColor6
	TokenColor7: $TokenColor7
	TokenColor8: $TokenColor8
	TokenColor9: $TokenColor9
